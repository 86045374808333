import { getCookie } from 'lib/cookie';
import { $, $e, csrfToken, EventOf, on, registerStartup } from 'lib/utils';

interface CurrentCartsItems {
  readonly items: number;
}

registerStartup(() => {
  getCart();
  on('change', 'form[data-cart-recalc] select[name^=quantity]', (ev: EventOf<HTMLSelectElement>) =>
    recalcCart(ev.currentTarget.form!),
  );
  on('click', 'form[data-cart-recalc] button[data-remove-item]', handleRemoveItem);

  on('change', '[data-change-refresh]', (ev: EventOf<HTMLSelectElement>) => {
    location.href = ev.currentTarget.value;
  });
});

async function getCart() {
  let items = parseInt(getCookie('cart') || '', 10);
  if (Number.isNaN(items) || items < 0) {
    const res = await fetch('/ajax/current_carts_items');
    const json = (await res.json()) as CurrentCartsItems;
    items = json.items;
  }

  const elem = $('cart-quantities') as HTMLOutputElement;
  if (items > 0) Object.assign(elem, { hidden: false, value: items });
}

async function recalcCart(form: HTMLFormElement) {
  try {
    const res = await fetch(form.dataset.cartRecalc!, {
      method: 'PATCH',
      mode: 'same-origin',
      credentials: 'include',
      body: new FormData(form),
      headers: { 'X-CSRF-Token': csrfToken() },
      redirect: 'manual',
    });
    if (res.type === 'opaqueredirect' || res.status === 204) {
      location.reload();
    } else if (!res.ok) {
      alert(
        res.headers.get('Content-Type')?.includes('application/json')
          ? (await res.json()).error
          : 'カートの再計算に失敗しました。再読込します。',
      );
      location.reload();
    } else {
      form.querySelector('.cart-form-intern')!.innerHTML = await res.text();
    }
  } catch (err) {
    alert(err);
  }
}

function handleRemoveItem(ev: EventOf<HTMLButtonElement>) {
  const e = ev.currentTarget;
  const form = e.form!;
  if (form.dataset.cartDisallowEmpty && form.querySelectorAll('select[name^=quantity]').length <= 1) {
    alert('カートの商品をすべて削除することはできません。代わりに注文を取り消してください。');
    return;
  }
  const quantity = e.closest('tr')!.querySelector<HTMLSelectElement>('select[name^=quantity]');
  if (quantity && confirm(`『${e.dataset.removeItem}』をカートから削除します。`)) {
    quantity.replaceChildren($e('option', { value: '0' }, '0'));
    quantity.value = '0';
    recalcCart(e.form!);
  }
}
