import { $, $1, EventOf, on } from 'lib/utils';

on('click', '#areamap a', (ev: EventOf<HTMLAnchorElement>) => {
  const target = $1(ev.currentTarget.getAttribute('href')!)!;
  $('map-overlay')!.classList.add('show');
  target.hidden = false;
  ev.preventDefault();
});
on('click', '#map-overlay .close', (ev: EventOf<HTMLButtonElement>) => {
  $('map-overlay')!.classList.remove('show');
  ev.preventDefault();
});
on('transitionend', '#map-overlay', (ev: EventOf<HTMLDivElement>) => {
  if (!ev.currentTarget.classList.contains('show')) {
    ([...ev.currentTarget.children] as HTMLElement[]).forEach((elem) => (elem.hidden = true));
  }
});
