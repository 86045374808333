import { $$, $1, EventOf, on, registerStartup } from 'lib/utils';

registerStartup(() => {
  $$<HTMLDivElement>('.mv-container').forEach((cntr) => {
    // const interval = Math.round(16000 / cntr.children.length) + 500;
    // cntr.style.setProperty('--zoom-duration', `${interval}ms`);
    cntr.classList.add('mv-init');
    cntr.addEventListener('animationend', (ev) => {
      if (cntr.dataset.stopAutoplay) return;
      const curr = (ev.target as HTMLElement).closest('.mv-link')!;
      curr.classList.remove('mv-active');
      const next = curr.nextElementSibling;
      if (next) next.classList.add('mv-active');
      else setTimeout(() => cntr.firstElementChild!.classList.add('mv-active'), 1500);
    });
    cntr.firstElementChild!.classList.add('mv-active');
  });
  on('click', '[data-mv-switch]', (ev) => {
    ev.preventDefault();
    const cntr = $1('.mv-container')!;
    cntr.dataset.stopAutoplay = 'true';
    cntr.querySelector('.mv-active')?.classList.remove('mv-active');
    cntr.children[+(ev.target as HTMLElement).dataset.mvSwitch!].classList.add('mv-active');
  });

  const fromExtern = getReferrerOrigin() !== location.origin;
  const navType = (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined)?.type;
  console.log(navType);
  $$('.wpop').forEach((pop) => {
    if (
      (fromExtern && (navType !== 'back_forward' || sessionStorage.getItem(`wpop.${pop.id}`) === 'closed')) ||
      navType === 'reload'
    ) {
      sessionStorage.removeItem(`wpop.${pop.id}`);
      pop.hidden = false;
    }
  });
  on('click', '.wpop-close', (ev: EventOf<HTMLButtonElement>) => {
    const pop = ev.currentTarget.closest('.wpop') as HTMLElement;
    sessionStorage.setItem(`wpop.${pop.id}`, 'closed');
    pop.addEventListener('transitionend', () => (pop.hidden = true));
    pop.style.opacity = '0';
  });
});

function getReferrerOrigin() {
  try {
    return new URL(document.referrer).origin;
  } catch (_err) {
    // ignore
  }
}
