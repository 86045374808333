import { $e, EventOf, on, csrfToken, registerStartup } from 'lib/utils';

registerStartup(() => {
  on('submit', '[data-require-onetime]', handleSubmit);
});

function template() {
  const input = $e('input', {
    type: 'text',
    value: '',
    inputMode: 'numeric',
    autocomplete: 'one-time-code',
    pattern: '\\d{6}',
    minLength: 6,
    maxLength: 6,
    required: true,
    spellcheck: false,
    className: 'text-3xl text-center p-2 disabled:bg-gray-200 disabled:text-gray-600',
    style: { letterSpacing: '1rem' },
  });
  const close = $e('button', { type: 'button', className: 'btn btn-secondary ml-4' }, 'キャンセル');
  const fieldset = $e('fieldset', { className: 'text-center' }, input, $e('div', { className: 'mt-4' }, close));
  const dialog = $e(
    'div',
    { className: 'fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40 flex justify-center items-center p-6' },
    $e(
      'div',
      { className: 'bg-white p-6 shadow-lg max-w-sm' },
      $e(
        'p',
        { className: 'text-sm mb-4 whitespace-pre-line' },
        '入力されたアドレスに確認コードを記載したメールをお送りしました。\nメールに記載された確認コードを入力してください',
      ),
      fieldset,
    ),
  );
  return { dialog, fieldset, input, close };
}

function query(obj: Record<string, string>): BodyInit {
  return new URLSearchParams(obj);
}

async function handleSubmit(ev: EventOf<HTMLFormElement>) {
  const form = ev.currentTarget;
  const submit = form.querySelector<HTMLButtonElement>('[type=submit]')!;
  if (!form.checkValidity()) return;

  ev.preventDefault();
  submit.disabled = true;

  const options: RequestInit = {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'include',
    headers: { 'X-CSRF-Token': csrfToken() },
  };

  const email = form.elements.namedItem(form.dataset.requireOnetime!) as HTMLInputElement;
  const mode = form.dataset.onetimeMode || 'verify';
  const order = form.dataset.order || '';
  const res = await fetch('/ajax/one_time_code', { ...options, body: query({ email: email.value, mode, order }) });
  if (!res.ok) {
    alert('確認コードを送信できませんでした。メールアドレスが正しいかどうか確認してください。');
    email.setCustomValidity('メールアドレスが正しいかどうか確認してください。');
    email.addEventListener('input', handleInputEmail);
    email.focus();
    submit.disabled = false;
    return;
  }
  if (res.status === 204) {
    form.submit();
    return;
  }

  const { dialog, fieldset, input, close } = template();
  const closeDialog = () => {
    dialog.remove();
    document.body.removeEventListener('keydown', handleKeydown);
    submit.disabled = false;
  };
  const handleKeydown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      closeDialog();
    }
  };
  input.addEventListener('input', async (ev) => {
    ev.stopPropagation();

    const value = input.value;
    if (!/^\d{6}$/.test(value)) {
      input.value = input.value.replace(/\D/g, '');
      return;
    }

    fieldset.disabled = true;
    const res = await fetch('/ajax/verify_one_time_code', { ...options, body: query({ code: input.value }) });
    if (res.ok) {
      form.submit();
      return;
    }

    if (res.status === 409) {
      alert('確認コードが一致しません。\n制限回数を超えました。再度やり直してください。');
      closeDialog();
      email.focus();
      return;
    }

    alert('確認コードが一致しません。\nメールを確認してください。');
    fieldset.disabled = false;
    input.value = '';
    input.focus();
  });
  close.addEventListener('click', closeDialog);
  document.body.addEventListener('keydown', handleKeydown);

  document.body.append(dialog);
  input.focus();
}

function handleInputEmail(ev: Event) {
  const email = ev.currentTarget as HTMLInputElement;
  email.setCustomValidity('');
  email.removeEventListener('input', handleInputEmail);
}
