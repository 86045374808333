import { $1, EventOf, on, registerStartup } from 'lib/utils';
import { setValid, setInvalid } from './validations';

const cardBrands = [
  [/^35/, 'cc-jcb', 16, 16, 3],
  [/^3[47]/, 'cc-amex', 15, 15, 4],
  [/^3[0689]/, 'cc-diners-club', 14, 16, 3],
  [/^4/, 'cc-visa', 16, 16, 3],
  [/^(?:2[2-7]|5[1-5])/, 'cc-mastercard', 16, 16, 3],
] as const;

registerStartup(() => {
  on('submit', 'form', handleSubmit);
  on(['input', 'focusout'], 'input[autocomplete="cc-number"]', checkCardNumber);
  on(['input', 'focusout'], 'input[autocomplete="cc-exp"]', checkCardExp);
});

async function handleSubmit(ev: EventOf<HTMLFormElement>) {
  const form = ev.currentTarget;
  if (!(form.checkValidity() && form.querySelector('input[autocomplete="cc-number"]:enabled'))) return;

  ev.preventDefault();
  const submits = form.querySelectorAll<HTMLButtonElement>('input[type=submit],button[type=submit]');
  submits.forEach((e) => (e.disabled = true));
  const ccElems = Object.fromEntries(
    Array.from(form.querySelectorAll<HTMLInputElement>('[autocomplete^="cc-"]')).map((elem) => [
      elem.getAttribute('autocomplete')!,
      elem,
    ]),
  );

  try {
    const data = await queryToken(ccElems);
    Object.entries(data).forEach(([key, value]) => {
      const elem = form.querySelector<HTMLInputElement>(`[data-${key}]`);
      if (elem) elem.value = value;
    });
    Object.entries(ccElems).forEach(([key, elem]) => {
      elem.required = false;
      elem.maxLength = elem.minLength = 0;
      elem.pattern = '';
      elem.readOnly = true;
      elem.value = data[key] || '****';
    });
    form.submit();
  } catch (err) {
    alert(err);
    submits.forEach((e) => (e.disabled = false));
  }
}

function checkCardNumber(ev: EventOf<HTMLInputElement>) {
  const elem = ev.currentTarget;
  const value = elem.value;
  const iconElem = $1(elem.dataset.cardBrand!)!;
  iconElem.className = 'fas fa-credit-card';

  if (value === '' || /\D/.test(value)) {
    setInvalid(elem, 'クレジットカード番号を数字で入力してください');
    return;
  }
  for (const [prefix, icon, minLength, maxLength, cscLength] of cardBrands) {
    if (prefix.test(value)) {
      iconElem.className = `fab fa-${icon}`;
      elem.maxLength = elem.minLength = 0;
      elem.maxLength = maxLength;
      elem.minLength = minLength;
      elem.pattern = `\\d{${minLength},${maxLength}}`;
      const cscElem = elem.form!.querySelector<HTMLInputElement>(`[autocomplete="cc-csc"]`);
      if (cscElem) {
        cscElem.maxLength = cscElem.minLength = 0;
        cscElem.minLength = cscElem.maxLength = cscLength;
        cscElem.pattern = `\\d{${cscLength}}`;
      }
      break;
    }
  }
  if (value.length >= elem.minLength && value.length <= elem.maxLength && luhn(value) === 0) {
    setValid(elem);
  } else {
    setInvalid(elem, 'クレジットカード番号を正しく入力してください。');
  }
}

function luhn(value: string) {
  const sum = value
    .split('')
    .reverse()
    .reduce((sum, n, index) => {
      const n2 = index % 2 == 1 ? +n * 2 : +n;
      return sum + (n2 <= 9 ? n2 : (n2 % 10) + Math.floor(n2 / 10));
    }, 0);
  return sum % 10;
}

function checkCardExp(ev: EventOf<HTMLInputElement, InputEvent>) {
  const elem = ev.currentTarget;
  const value = elem.value;
  if (/^\d{2,}$/.test(value) && (ev.type === 'focusout' || ev.data)) {
    elem.value = `${value.slice(0, 2)}/${value.slice(2)}`;
    return;
  }
  const match = /^(0\d|1[012])\/(\d\d)$/.exec(value);
  if (match) {
    const now = new Date();
    const nowYM = `${now.getFullYear() - 2000}${`00${now.getMonth() - 1}`.slice(-2)}`;
    const valueYM = `${match[2]}${match[1]}`;
    console.log(`checkCardNumber: ${valueYM} >= ${nowYM}`);
    if (valueYM >= nowYM) {
      setValid(elem);
      return;
    }
  }
  setInvalid(elem, '有効期限を 月/年 の形式で入力してください。');
}

async function queryToken(elems: Record<string, HTMLInputElement>): Promise<Record<string, string>> {
  const res = await fetch('https://api.veritrans.co.jp/4gtoken', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      card_number: elems['cc-number']?.value,
      card_expire: elems['cc-exp']?.value,
      security_code: elems['cc-csc']?.value,
      cardholder_name: elems['cc-name']?.value,
      token_api_key: await getTokenApiKey(),
      lang: 'ja',
    }),
  });
  const data = res.headers.get('Content-Type') === 'application/json' ? await res.json() : undefined;
  if (res.ok && data?.status === 'success') {
    return { 'cc-token': data.token, 'cc-number': data.req_card_number, 'cc-token-expires': data.token_expire_date };
  }
  const mesg = data?.message || data?.code || res.status;
  throw new Error(`クレジットカード番号のトークンが取得できませんでした。(${mesg})`);
}

async function getTokenApiKey(){
  const res = await fetch('/ajax/token_api_key');
  const data = await res.json();
  return data.token_api_key;
}
