import 'lib/rails';
import 'lib/luxon-setup';

import { runStartups } from 'lib/utils';
import 'app/validations';
import 'app/enableon';
import 'app/onetime';
import 'app/cart';
import 'app/calendar';
import 'app/credit';
import 'app/welcome';
import 'app/map';

runStartups();
