const document = window.document;

const startups: (() => void)[] = [];
export function registerStartup(handler: () => void) {
  startups.push(handler);
}
export function runStartups() {
  startups.forEach((handler) => handler());
}

// shorthand

export const $ = (id: string) => document.getElementById(id);
export const $1 = <E extends Element = HTMLElement>(selectors: string) => document.querySelector<E>(selectors);
export const $$ = <E extends Element = HTMLElement>(selectors: string) => document.querySelectorAll<E>(selectors);

export const $tap = <E>(obj: E, ...cbs: ((obj: E) => void)[]) => (cbs.forEach((cb) => cb(obj)), obj);
export const $n = (num: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-US', options).format(num);

// event observers

export type EventOf<T extends EventTarget, E extends Event = Event> = E & { readonly currentTarget: T };

export function on<E extends Event = Event>(events: string | string[], selectors: string, listener: (evt: E) => void) {
  const handler = (ev: Event) => {
    const target = (ev.target as Element | null)?.closest(selectors);
    if (target) handleEvent(ev, target, listener as EventListener);
  };
  for (const event of Array.isArray(events) ? events : [events]) {
    document.body.addEventListener(event, handler);
  }
}

function handleEvent(ev: Event, currentTarget: Element, listener: EventListener) {
  Object.defineProperty(ev, 'currentTarget', { get: () => currentTarget, configurable: true });
  listener.call(currentTarget, ev);
  delete (ev as any).currentTarget; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// META

export const $meta = (name: string) => $1(`meta[name="${name}"]`)?.getAttribute('content') || undefined;
export const csrfToken = () => $meta('csrf-token')!;

// elements

type TagProps<K extends keyof HTMLElementTagNameMap> = {
  readonly [key in keyof HTMLElementTagNameMap[K]]?: key extends 'style'
    ? Partial<Readonly<CSSStyleDeclaration>>
    : HTMLElementTagNameMap[K][key];
};

export function $e<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  props?: TagProps<K>,
  ...children: (string | Node)[]
): HTMLElementTagNameMap[K];
export function $e<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  props?: TagProps<K>,
  attributes?: Record<string, string>,
  ...children: (string | Node)[]
): HTMLElementTagNameMap[K];
export function $e<K extends keyof HTMLElementTagNameMap>(tagName: K, props?: TagProps<K>, ...rest: unknown[]) {
  const element = document.createElement(tagName);
  if (props) {
    const { style, ...props2 } = props;
    Object.assign(element, props2);
    Object.assign(element.style, style);
  }
  if (rest[0] && !(typeof rest[0] === 'string' || rest[0] instanceof Node)) {
    const attributes = rest.shift() as Record<string, string>;
    for (const [key, value] of Object.entries(attributes)) element.setAttribute(key, value);
  }
  element.append(...(rest as (string | Node)[]));
  return element;
}

export function $icon(className: string, props?: TagProps<'span'>) {
  return $e('span', props, $e('i', { className }));
}

export function $hidden(name: string, value?: unknown) {
  return $e('input', { type: 'hidden', name, value: value == null ? '' : `${value}` });
}

export function $hiddens(name: string, values: Record<string, unknown>) {
  return Object.entries(values).map(([key, value]) => $hidden(`${name}[${key}]`, `${value}`));
}
