let parsedCookie: { readonly [key in string]?: string } | undefined;

export function getCookie(name: string) {
  parsedCookie ||= document.cookie.split(/;\s*/).reduce((acc, item) => {
    const [key, value] = item.split('=');
    acc[decodeURIComponent(key)] = decodeURIComponent(value);
    return acc;
  }, {} as Record<string, string>);
  return parsedCookie[name];
}
