const halfkana = /[\uFF61-\uFF9F]+/g;
const hiragana = /[\u3041-\u3096]/g;

interface Options {
  readonly z2h?: boolean;
  readonly katakana?: boolean;
}

const z2hCheck = {
  type: ['email', 'tel', 'url'],
  inputmode: ['numeric'],
  autocomplete: ['email', 'tel', 'postal-code', 'cc-name'],
};
const kanaCheck = {
  'data-normalize': ['katakana'],
};

function check(elem: Element, checker: Record<string, unknown[]>) {
  return Object.entries(checker).some(([key, values]) => values.includes(elem.getAttribute(key)));
}

export function normalize(str: string, opts: Element | Options = {}) {
  if (opts instanceof Element) {
    opts = { z2h: check(opts, z2hCheck), katakana: check(opts, kanaCheck) };
  }
  str = opts.z2h ? str.normalize('NFKC') : str.normalize('NFC').replace(halfkana, (s) => s.normalize('NFKC'));
  if (opts.katakana) str = str.replace(hiragana, (s) => String.fromCharCode(s.charCodeAt(0) + 0x60));
  return str;
}
